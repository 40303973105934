.shblueprint {
  position: relative;
  
  canvas#floorCanvas {
    flex: 1;
    overflow: auto;
  }

  .blueprint-img {
    display: none;
    border: 1px solid green;
  }
}
