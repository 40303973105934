$al: 0.7;
$col: #ffffff;

.screenshot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}

.scr-top {
  background-color: rgba($color: $col, $alpha: $al);
}

.scr-middle {
  display: flex;
  flex-direction: row;
}

.scr-left {
  background-color: rgba($color: $col, $alpha: $al);
  height: 100%;
}

.scr-center {
  height: 100%;
}

.scr-right {
  background-color: rgba($color: $col, $alpha: $al);
  height: 100%;
  flex: 1 1 auto;
}

.scr-bottom {
  background-color: rgba($color: $col, $alpha: $al);
  height: 100vh;
}
