.users {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  flex: 1 0 auto;
  font-family: "Montserrat-Bold";
  color: #333333;
  font-size: 20px;
  border-top: 1px solid #e9ecef;
  padding-top: 25px;
  padding-left: 30px;

  .plus{
    margin-top: 40px;
  }

  .title {
    font-size: 19px;
    color: #333333;

    &.title-app {
      margin-top: 60px;
    }
  }

  .cnt {
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    margin-right: 15px;
    padding: 20px;
    padding-left: 24px;
    margin-left: -10px;
    padding-bottom: 15px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
  }
}
