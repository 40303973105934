.user {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  font-family: "Montserrat-Bold";
  font-size: 20px;
  margin-left: -5px;

  &.top-user {
    >.btn-delete{
     margin-top: 65px;
    }
  }

  .inp-cnt {
    margin-left: 10px;
  }

  .input {
    font-size: 14px;
    color: #333333;
    font-family: "Montserrat-Bold";
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 0px;

    > .input-top {
      margin-bottom: 10px;
    }

    input {
      margin-left: 0px;
      color: #252422;
      margin: 0px 0px;
      width: 200px;
    }
  }

  .btn-delete {
    margin-top: 25px;
    margin-left: 10px;
  }
}
