.right {
  display: flex;
  flex-direction: column;
  background-color: white;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  .save{
    position: absolute;
    top: 8px;
    right: 0px;
    z-index: 1;
  }

  .tabs {
    display: flex;
    margin-top: 25px;
    margin-left: 35px;
    padding-bottom: 3px;
    z-index: 1;
    position: relative;
  }

  .tab-content {
    border-top: 1px solid #E9ECEF;
    padding-top: 25px;
    padding-left: 25px;
  }
}
