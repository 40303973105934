.panel{
 
   padding: 17px;
   padding-left: 34px;
   font-family: "Montserrat-Bold";
   font-size: 12px;
   color: #66615B;
   padding-top: 24px;
   padding-bottom: 20px;
   border-bottom: 1px solid #E9ECEF;
   min-height: 15px;
   background-color: white;
      
   
}