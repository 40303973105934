.cmp-panel {
  .root-plus {
    margin-left: 20px;
    margin-top: -5px;
  }

  .plus {
    margin-left: -9px;
    position: relative;
    top: 7px;

    .button2-left {
      font-size: 18px;
      width: 22px;
      font-family: "Montserrat-Bold";
      font-weight: bold;
      padding: 0px;
    }

    .button2-label {
      color: #aa0000;
      font-size: 18px;
      position: absolute;
      left: 30px;
      top: -3px;
      font-weight: bold;
    }
  }
}

.tree3 {
  margin-top: 12px !important;

  .items-row {
    font-size: 18px;
    font-family: "Montserrat-Regular";
    color: #5d5d5d;

    .chk {
      display: flex;
   
      .check{
        margin-right: 15px;
      }
    
      .tx {
      }
    }
  }

  .plus-component {
    &.probe {
      padding-left: 20px;
    }
  }

  .items {
    .plus {
      margin-left: 12px;
    }

    .plus-component {
      &.probe {
        margin-left: 40px;
        padding-left: 0px;
      }
    }
  }

  .tree-node {
    margin-top: 15px;
  }

  .tree-sub-node {
    .plus {
      margin-left: -7px;
    }
  }

  .tree-child-ind {
    svg {
      position: relative;
      margin-right: 8px;
      top: 0px;
    }

    &.open {
      svg {
        margin-left: -5px;
      }
    }
  }
}
