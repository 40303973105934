.input-top {
  
  .top{
    font-size: 16px;
    color: #9D9D9D; 
    font-family: "Montserrat-Regular",
  }

  .input-top-right{
    color: #AA0000;
  }
}
