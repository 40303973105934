.button {
  color: white;
  background-color: #aa0100;
  border-radius: 19px;
  padding: 11px 22px;
  font-size: 12px;
  text-align: center;
  font-family: "Montserrat-Bold";

  &.disabled {
    background-color: rgb(177, 177, 177);
    pointer-events: none;
    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
  }

  i {
    padding-top: 3px;
  }

  img {
  }

  .button-content {
    white-space: nowrap;
  }
}
