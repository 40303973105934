.test1{
    margin-top: -7px;
    position: relative;
    
    .input{
        width: 300px;
    }

    .title{
        font-family: "Montserrat-Bold";
        color:#252422;
        font-size: 14px;
        position: absolute;
        top: -30px;

    }
}