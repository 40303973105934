.bar-button {
  padding: 15px 15px;
  margin-bottom: 0px;

  &.bottom{
    position: absolute;
    bottom: 0px;
  }

  img {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
  }

  &.disabled {
    pointer-events: none;
  }
}
