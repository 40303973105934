.input:focus-within {
  // border: 1px solid #bbacac;
}

.input {
  display: flex;
  margin: 10px 0px;
  color: #252422;
  font-size: 14px;

  &.error {
    input {
      border: 1px solid red;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

  input {
    /* border: 1px solid #fdeaea; */
    border: 1px solid #dddddd;
    flex: 1 1 auto;
    border-radius: 6px;
    font-family: "";
    font-size: inherit;
    padding: 10px;
    color: inherit;
    margin-left: 15px;
    margin-top: -10px;
    font-family: "Montserrat-Regular";

    &::placeholder {
      color: #383734;
    }

    &:focus {
      outline: none;
    }
  }

  input:focus {
  }

  img {
    margin-top: 12px;
    margin-left: 13px;
    margin-right: 3px;
    width: 16px;
  }

  .label {
  }

  .label2 {
    margin-left: 15px;
  }
}
