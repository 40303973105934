.exports {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  flex: 1;
  font-family: "Montserrat-Bold";
  color: #333333;
  font-size: 20px;
  border-top: 1px solid #e9ecef;
  padding-top: 25px;
  padding-left: 30px;

  .title {
    font-size: 19px;
    color: #333333;
  }

  .cnt {
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    margin-right: 15px;
    padding: 20px;
    padding-left: 24px;
    margin-left: -10px;
    padding-bottom: 15px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
  }

  .subtitle {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .subcnt {
    .export {
      display: flex;
      flex-direction: row;
      position: relative;

      .subtitle{
        position: absolute;
        top: -38px;
      }

      .input {
        width: 20%;
        max-width: 120px;
        min-width: 100px;
        margin-left: 20px;

        .label2{
          font-family: "Montserrat-Regular";
        }
     
        &.name {
          min-width: 300px;
          max-width: 400px;
          width: 50%;
          margin-right: 30px;
        }

        &.width{
          margin-right: 50px;
        }

      
     
        input {
          margin-left: 0px;
          width: 100%;
          margin-bottom: 5px;
          font-family: "Montserrat-Regular";
        }

        .label2 {
          margin-left: 10px;
        }
      }
    }
  }
}
