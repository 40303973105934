.floor{
     margin-top: 10px;
     color: #2c2c2c;
     font-family: "Montserrat-Regular";

     &.open {
        color: #aa0100;
        font-family: "Montserrat-Bold";
      }
 
      &.disabled{
         cursor: no-drop;
      }
    
     .investigations{
         margin-left: 15px;
     }
}