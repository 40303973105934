.bar {
  //border-left: 1px solid red;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;

  .logo {
    img {
      width: 57px;
    }
  }

  .btns {
    margin-top: 15px;
  }
}
