@font-face {
  font-family: Andale-Mono;
  src: url("../assets/fonts/Andale Mono.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../assets/fonts/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat-Medium;
  src: url("../assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("../assets/fonts/Montserrat-Bold.ttf");
}

body,html {
  background-color: white;
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
 // overflow-x: hidden;
}

#root {
  height: inherit;
}

.router {
  height: inherit;
  display: flex;
}
