.damage {
  display: flex;
  padding: 13px 0px;
  font-family: "Montserrat-Regular";
  font-weight: bold;
  font-size: 14px;
  color: #2c2c2c;
  border-bottom: 1px solid #e9ecef;
  padding-right: 20px;
  position: relative;

  .tt {
    position: absolute;
    right: 0px;
    top: 15px;

    .button2 {
      svg {
        .c {
          fill: #aa0100;
        }
      }
    }
  }

  &.last {
    border-bottom: unset;
  }

  &:hover {
    cursor: pointer;
  }

  .damage-name {
    width: 265px;
  }

  &.selected {
    color: #aa0100;
    font-family: "Montserrat-Bold";
  }
}
