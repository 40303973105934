.check {
  display: flex;
  font-family: "Montserrat-Regular";
  color: #252422;
  font-size: 14px;

  .label {
    margin-left: 15px;
    margin-top: 2px;
  }

  .checkmark {
    width: 20px;
    height: 20px;
    background-color: #66615b;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover{
      cursor: pointer;
    }

    svg {
      width: 12px;
      height: 12px;
      fill: white;
    }
  }

  input:checked ~ .checkmark {
    background-color: red;
  }
}
