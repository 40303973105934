.plnright {
  position: fixed;
  top: -6px;
  right: 0px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
  padding-top: 180px;
  height: calc(100vh - 120px); // padding top
  font-family: "Montserrat-Bold";
  font-size: 14px;
  width: 240px;
  overflow-y: auto;

  .checks {
    margin-top: 5px;
  }

  .check {
    margin-top: 10px;
    font-size: 12px;

    .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 95%;
      overflow: hidden;
    }
  }

  .sliders {
    display: flex;
    flex-direction: column;
    margin-top: 35px;

    .slider {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .sliders-lb {
    margin-bottom: 5px;
  }

  .btm {
    margin-top: 20px;
  }

  .btm-scr {
    margin-top: 10px;
    margin-left: -20px;
    margin-bottom: 120px; // for scrolling
  }

  .shitem {
    margin-top: 10px;
    display: flex;
    height: 20px;

    .button {
      max-width: 120px;
      background-color: unset;
      color: #252422;
      .button-content {
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }

    .x {
      color: #aa0100;
      font-weight: bold;
      margin-top: 10px;
      margin-left: 7px;
      cursor: pointer;
      font-size: 16px;
    }
  }

  + .popup-edit {
    &.pat {
      input {
        margin-left: 0px;
      }
    }

    &.scr {
      .popup-modal {
        max-width: 700px;
        border-radius: 10px;

        .popup-title {
          background: white;
          border-bottom: unset;
          padding-top: 30px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          margin-bottom: -5px;
        }

        .popup-cnt {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      input {
        width: 100%;
        margin-top: 0px;
      }

      .input-top {
        flex: unset;
        flex-basis: 145px;
     
        input {
          font-weight: bold;
          font-size: 14px;
        }
        .top {
          font-size: 13px;
        }
      }

      .pe-it2 {
        flex: 1;
        .input-top {
          flex: 1;
        }
      }
    }
  }
}
