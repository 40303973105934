.damagedata {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  flex: 1 0 auto;
  font-family: "Montserrat-Bold";
  color: #333333;
  font-size: 20px;
  border-top: 1px solid #e9ecef;
  padding-top: 25px;
  padding-left: 30px;
  .pat {
    .popup-modal {
      width: 700px;
      max-width: 700px;
      border-radius: 10px;

      .popup-title {
        background: white;
        border-bottom: unset;
        padding-top: 30px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        margin-bottom: -5px;
      }

      input {
        font-weight: bold;
        font-size: 14px;
      }

      .popup-cnt {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .top {
          font-size: 13px;
        }

        .radio-item {
        }
      }
    }

    input {
      margin-left: 0px !important;
      margin-top: 0px;
    }
  }

  .title {
    //font-size: 19px;
    // color: #333333;
  }

  .cnt {
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    margin-right: 15px;
    padding: 20px;
    padding-left: 24px;
    margin-left: -10px;
    padding-bottom: 15px;
    margin-top: 30px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    border: 1px solid #dddddd;

    input {
      margin-left: 0px;
      width: 90%;
      margin-bottom: 5px;
      font-family: "Montserrat-Regular";
    }
  }

  .subtitle {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .subcnt {
    display: flex;
  }

  .tl {
    width: 40%;
    min-width: 180px;
    margin-right: 20px;

    .tl1 {
      margin-right: 5px;
    }

    .tl2 {
    }
  }

  .tc1 {
    min-width: 240px;
    width: 20%;
    position: relative;
    z-index: 2;
  }

  .tc2 {
    width: 20%;
    min-width: 190px;
    position: relative;
    z-index: 1;
  }

  .tr {
    width: 40%;
    min-width: 150px;
    .tr1 {
      margin-right: 5px;
      width: 65%;
    }
    .tr2 {
      width: 35%;
    }
  }

  .bottom {
    margin-top: 30px;
    margin-bottom: 30px;

    .cnt {
      display: flex;
      flex-direction: column;

      .check {
        margin-bottom: 25px;
      }

      .tests {
        margin-top: 5px;
        position: relative;

        .test {
          position: unset;
          .arrow-right {
            position: absolute;
          }
        }
      }
    }
  }

  .popup-modal {
    width: 500px;
  }

  .pop-lb {
    margin-top: 30px;
    margin-bottom: 25px;
    margin-left: 20px;

    input {
      margin-left: 70px;
      font-family: "Montserrat-Regular";
    }
  }

  .pop-del {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .p-btm {
    display: flex;
    justify-content: space-between;

    .button {
      background-color: #70c1b3;
      border-radius: 5px;
    }

    .btn-left {
    }

    .btn-right {
      background-color: #d0d0d0;
    }
  }

  .damage-btm {
  }

  .tests-cnt {
    display: flex;
  }

  .test-cnt {
    margin-left: 20px;
    display: flex;
    top: 0px;
    left: 250px;
    //width: 100px;
    color: #252422;
    font-family: "Montserrat-Regular";
  }

  .settings-lists {
    display: flex;
    //top: 39px;
    left: 0px;
    position: relative;
    width: 0px;
    margin-left: 10px;

    
  }

  .items-list {
    padding-top: 10px;
    background-color: white;
    margin-left: -10px;

    .plus {
      &.no-items {
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 10px 10px;
        padding-bottom: 6.5px;
        margin-top: -10px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-left: unset;
      }
    }

    .button2-label {
      font-size: 14px;
      font-family: "Montserrat-Regular";
    }

    .settings-item {
      display: flex;
      margin-bottom: 13px;
      font-size: 14px;
      font-family: "Montserrat-Regular";
      border: 1px solid #dddddd;
      border-radius: 5px;
      padding: 10px 10px;
      padding-bottom: 5px;
      margin-top: -10px;

      .item-name {
        margin-right: 10px;
        white-space: nowrap;
        min-width: 50px;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
        }
      }

      .btn-delete {
        margin-top: 5px;
        margin-left: 3px;
      }
    }
  }

  .test-radios {
    position: absolute;
    height: 0px;
    bottom: 53px;

    .radio-item-label {
      margin-top: -2px;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      margin-right: 20px;
    }
  }
}
