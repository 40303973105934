.test2 {
  margin-top: -7px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  .header-titles{
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-family: "Montserrat-Bold";
    color: #252422;
    font-size: 14px;
    // position: absolute;
    top: -30px;

    &.title2 {
      // left: 325px;
      margin-right: 100px;
    }
  }

  .input {
    width: 100px;
    margin-right: 20px;
  }

  .test2-item {
    border: 1px solid #dddddd;
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-right: 15px;
    overflow: visible;
    width: 100%;

    .test2-rub1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: -15px;
      margin-left: 10px;

      .test2-rub-cnt {
        display: flex;
        align-items: center;

        .test2-select-container {
          margin-right:0px;
        }

        .check {
          margin-bottom: 0px;
        }
      }

      .amp {
        margin-top: 10px;
      }

      .amp1 {
        margin-left: -8px;
        margin-right: 10px;
      }
    }

    .test2-unit-line {
      display: flex;
      flex-direction: column;
      width: 260px;

      .test2-unit-line-cnt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .unit-label {
          width: 66px;
          margin-left: 10px;
        }
      }
    }

    .delete-button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .button {
        color: white;
        background-color: #aa0100;
        border-radius: 19px;
        padding: 11px 22px;
        font-size: 12px;
        text-align: center;
        font-family: "Montserrat-Bold";
        margin-left: 10px;
      }
      
      .button-img {
        margin-left: 10px;
      }
    }

    .react-dropdown-select-dropdown{
      width: 135px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .test2-select-min-color-container {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .q-min {
      margin-right: 10px;
    }

    .q-max {
      margin-left: 5px;
      input {
        //text-align: left !important;
      }
    }

    .quantity {
      width: 70px;
      display: flex;
      align-items: center;

      input {
        text-align: right;
        margin: 0px;
      }
    }

    .unit {
      width: 115px;
      display: flex;
      align-items: center;

      input {
        text-align: right;
        margin: 0px;
      }
    }

    .unit1 {
      margin-left: 15px;
    }

    .unit2 {
      margin-left: 5px;
      input {
        text-align: center;
      }
    }

    .industry {
      width: 300px;
      margin-left: 15px;
      // height: 33px;
      display: flex;
      align-items: center;

      input {
        margin: 0px;
      }
    }

    .color {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      margin: 5px;

      cursor: pointer;
//       #FFBAC5 		#FF3178		#C75353
// #FCF19B		#FFFF00		#EADA1E
// #B6FDC4		#60FFBC		#7ADD32
// #B7FFFF		#60F3FF		#1DD2D9
      &.color11 {
        background-color:#C75353;
      }

      &.color10 {
        background-color:#FF3178;
      }

      &.color9 {
        background-color:#FFBAC5;
      }

      &.color8 {
        background-color:#EADA1E;
      }

      &.color7 {
        background-color:#FFFF00;
      }

      &.color6 {
        background-color:#FCF19B;
      }

      &.color5 {
        background-color: #7ADD32;
      }

      &.color4 {
        background-color:#60FFBC;
      }

      &.color3 {
        background-color: #B6FDC4;
      }

      &.color2 {
        background-color: #1DD2D9;
      }

      &.color1 {
        background-color: #60F3FF;
      }

      &.color0 {
        background-color:#B7FFFF;
      }

      &.small {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        margin: 0px;
        margin-left: -2px;
      }
    }

    .select {
      width: 25px;
      height: 37px;
      padding: 0px;
      margin: 0px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      color: #252422;
      font-size: 14px;
      /* border: 1px solid #fdeaea; */
      border: 1px solid #dddddd;
      flex: 1;
      border-radius: 6px;

      &:focus {
        box-shadow: unset;
      }

      &:focus-within {
        box-shadow: unset;
      }

      &.colors {
        /* border: 0px solid #fdeaea; */
        border: 0px solid #dddddd;

        svg {
          display: none;
        }
      }

      input {
        display: none;
      }
    }
  }

  .plus {
    margin-top: 13px;
  }
}
