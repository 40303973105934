.settings {
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: auto;

  .pad{

    .popup-modal{
      max-width: 600px !important;
    }

    input{
      margin-left: 0px !important;
      margin-top: 0px;
    }
  }

  .plus {
    margin-left: 38px;
    margin-top: 20px;

    .button2-label {
      font-family: "Montserrat-Medium";
    }
  }

  .panel {
    width: 305px;
    border-right: none !important;
    margin-left: 5px;
  }

  .damages {
    flex: 1 1 auto;

    .damages-damage {
      padding-top: 15px;
      border-right: 1px solid #e9ecef;
      margin-left: 38px;
    }
  }

  .footer {
    margin-top: 30px;
    margin-left: 30px;
  }
}
