.sample-layer{
    
    color: #2c2c2c;
    font-family: "Montserrat-Regular";
  
    &.selected {
      color: #aa0100;
      font-family: "Montserrat-Bold";
    }

    margin-top: 10px;
}