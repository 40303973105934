.white-panel {
  font-size: 14px;
  margin-right: 15px;
  padding-left: 24px;
  margin-left: -20px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-weight: bold;
  margin-bottom: 20px;

  .title {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
  }

  .content {
    background-color: white;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid #dddddd;
    margin-top: 30px;
  }
}
