.text-area {
  display: flex;
  margin: 5px 0px;
  color: #9D9D9D;
  font-size: 18px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  font-family: "Montserrat-Regular";
  
 
  .textarea:focus-within {
    // border: 1px solid #bbacac;
  }

  textarea::-webkit-inner-spin-button,
  textarea::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    border: none;
    flex: 1;
    border-radius: 6px;
    font-family: "";
    font-size: inherit;
    padding: 10px;
    color: inherit;
    margin-left: 0px;
    margin-top: 0px;
    background-color: unset;
    font-family: inherit;
   
    &::placeholder {
      color: #383734;
    }

    &:focus {
      outline: none;
    }
  }

  textarea:focus {
  }

  img {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 3px;
    margin-right: 5px;
  }

  .label {
  }

  .label2 {
    margin-left: 15px;
  }
}
