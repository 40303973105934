.popup-confirm {
  .popup-modal {
    width: 90%;
    max-width: 490px;
  }

  .pedit-top {
    font-family: "Montserrat-Bold";
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;
    color: #252422;
    font-weight: bold;
    margin-top: 10px;

    .pe-it2 {
      margin-left: 15px;
      display: flex;
      flex: 0.2;
   
      label {
        width: 100%;
        font-size: 16px;
      }
    }
  }

  &.top {
    .popup-title {
      font-size: 16px;
      color: #252422;
      font-weight: bold;
      margin-top: 10px;
    }

    .input-top {
      flex: 1;
      &.top {
        font-size: 16px;
        margin-top: 25px;
      }
    }
  }

  .popup-edit-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 15px;
    height: 38px;

    .left {
      margin-left: -15px;
      display: flex;
      flex-direction: row;
      .button3 {
        background-color: #bebebe;
      }

      .err {
        margin-left: 20px;
        color: #b72a29;
        font-family: "Montserrat-Bold";
        width: 150px;
        font-size: 12px;
        width: 0px;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      overflow: unset;
    }

    .button {
      margin-left: 15px;
    }
  }

  .input {
    color: #5d5d5d;
    font-size: 18px;
  }

  .input input {
    margin-left: 0px;
  }
}
