.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  background-color: rgba($color: black, $alpha: 0.75);
 
 .popup-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #70c1b3;
    color: white;
    width: 24px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    padding: 2px;
    padding-top: 4px;
    transform: translate(50%, -50%);

    &:hover{
      cursor: pointer;
    }
  }

  .popup-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 1px rgba(55, 55, 55, 0.75);
    border-radius: 5px;
  }

  .popup-title {
    background: #f9f9f9;
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 20px;
    line-height: 1;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 14px;
    font-family: "Montserrat-Regular";
  }

  .popup-cnt {
    background-color: white;
    padding: 20px;
    padding-bottom: 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow-y: auto;
    max-height: 95vh;
   
  }
}
