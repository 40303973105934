.popup-probe {
  .popup-modal {
    width: 90%;
    max-width: 990px;
  
    .popup-title{
      display: none;
    }

    .popup-cnt{
      padding-top: 1px;
      padding-right: 0px;
     
      .probes-cnt{
        margin-bottom: 0px;
      }
   }
  }

  
}
