.button2 {
  color: white;
  font-size: 14px;
  text-align: center;
  font-family: "Montserrat-Bold";
  display: flex;

  .button2-lb-left {
    color: #252422;
    font-size: 14px;
    margin-right: 10px;
    font-family: "Montserrat-Regular";

    &:hover {
      cursor: pointer;
    }
  }

  .button2-left {
    background-color: #aa0100;
    border-radius: 100%;
    padding: 0px 7px;
    font-size: 20px;
    margin-top: -4px;
    margin-right: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .button2-left-img {
    height: 26px;
    margin-top: -4px;
    margin-right: 8px;
    &:hover {
      cursor: pointer;
    }

    
  }

  .button2-label {
    color: black;
    font-size: 14px;
    font-family: "Montserrat-Regular";

    &:hover {
      cursor: pointer;
    }
  }
}
