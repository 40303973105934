.button1 {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .button {
    background-color: unset;
  }

  .button1-value {
    text-align: center;
    color: #aa0000;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;

  }
}
