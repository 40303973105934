$sldH : 8px;
$thH:16px;

@function makelongshadow($color, $size) {
  $val: 0px 0 0 $size $color;
  
  @for $i from 6 through 240 {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }
  
  @return $val;
}

.slider {
  display: flex;
  flex-direction: column;
 
  .label{
    margin-bottom: 5px;
  }

  input {
    appearance: none;
    overflow: hidden;
    height: $thH;

    &:focus {
      box-shadow: none;
      outline: none;
    }
   
    
    &::-webkit-slider-thumb {
      appearance: none;
      width: $thH;
      height: $thH;
      background: #FFFFFF;
      position: relative;
      cursor: pointer;
      border-radius: 100%;
      margin-top: -4px;
      border: 1px solid rgba(#888888,0.5);
      box-shadow: makelongshadow(#E5E5E5,-4px);
    }

    &::-moz-range-thumb {
      appearance: none;
      width: $thH;
      height: $thH;
      background: #FFFFFF;
      position: relative;
      cursor: pointer;
      border-radius: 100%;
      margin-top: -4px;
      border: 1px solid rgba(#888888,0.5);
      box-shadow: makelongshadow(#E5E5E5,-4px);
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: $sldH;
      background: #AA0100;
      border-radius: 10px;
    }

    &::-moz-range-track {
      width: 100%;
      height: $sldH;
      background: #AA0100;
      border-radius: 10px;
    }
  }
}
