.plmain {
  display: flex;
  flex-direction: column;
  flex: 5;

  .shsettings {
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: flex-start;
    font-family: "Montserrat-Regular";

    .input {
      margin-left: 25px;

      input {
        width: 28px;
        font-size: 12px;
      }
    }

    .plmain-select {
      color: white;
      background-color: #aa0100;
      border-radius: 19px;
      padding: 5px 22px;
      font-size: 12px;
      text-align: center;
      font-family: "Montserrat-Bold";
      padding-right: 30px;
      width: 220px;
      border: unset;

      &:focus-within {
        outline: none;
        box-shadow: none;
      }

      input {
        &::placeholder {
          color: white;
          font-size: 12px;
          font-family: "Montserrat-Bold";
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .react-dropdown-select-item-selected {
      background-color: #f1f0f0;
      color: black;
    }

    .react-dropdown-select-dropdown {
      border: 1px solid rgb(216, 216, 216);
      border-radius: 10px;
      margin-top: 0px;
      font-family: "Montserrat-Bold";
      font-size: 12px;
      text-align: left;
      color: black;

      span {
        padding: 10px 25px;
      }
    }
  }
}
