.company {
  display: flex;
  padding: 13px 0px;
  font-family: "Montserrat-Regular";
  font-weight: bold;
  font-size: 14px;
  color: #2c2c2c;
  border-top: 1px solid #e9ecef;
  padding-right: 0px;


  .company-id {
    margin-right: 10px;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 30px;
    cursor: pointer;
  }

  .company-name {
    width: 265px;
    cursor: pointer;
  }

  &.selected {
    color: #aa0100;
    font-family: "Montserrat-Bold";
  }

  &.disabled {
    cursor: no-drop;
  }
  
  .company-floors {
    margin-left: 15px;
  }
}
