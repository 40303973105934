.tab3 {
  font-family: "Montserrat-Regular";
  font-size: 14px;
  color: #66615b;
  margin-left: 10px;
  display: flex;
  margin-top: 15px;

  .probes {
    //width: 100px;

    .title {
      font-family: "Montserrat-Bold";
      color: #252422;
      margin-bottom: 395px;
    }

    .probe {
      padding-right: 40px;
      height: 46.4px;
      white-space: nowrap;

      
    }
  }

  .tests {
    margin-top: -17px;
    display: flex;
    margin-left: -20px;

    .test {
      width: 275px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-left: 25px;

      .t2{
        .input{
          width: 245px;
        }
      }

      .test2 {
        margin-top: 0px;
        margin-left: 0px;

        .select {
          padding: 0px !important;
        }

        .input {
          width: 35px;
        }

        .react-dropdown-select {
          padding: 0px !important;
          margin: 0px !important;
          width: 30px;
        }

        .test2-item {
          justify-content: flex-end;
          border: none;
          flex-wrap: wrap;
          width: fit-content;
          margin-right: 0px;

          .unit {
            width: 115px;
            display: flex;
            align-items: center;
      
            > input {
              text-align: right;
              margin: 0px;
              width: 147px !important;
            }
          }
        }

        .test2-rub1 {
          margin-left: 5px;
          margin-right: 0px;

          .amp1 {
            width: 5px;
            margin-left: 20px;
            margin-top: 14px;
          }

          input {
            width: 35px !important;
            margin-left: 0px;
          }

          .q-min {
            margin-right: 10px;
            margin-top: 3px;
            margin-bottom: 3px;
            width: 60px;
          }

          .q-max {
            margin-left: 25px;
            width: 30px;
            margin-top: 13px;
          }

          .amp2 {
            margin-left: 31px;
            margin-right: 10px;
            margin-top: 14px;
          }

          .unit1 {
            margin-left: -3px;
            width: 110px;
          }

          .unit2 {
            margin-left: 25px;
            width: 115px;
          }

          .test2-rub-cnt {
            width: 100px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .select2 {
        position: absolute;
        top: 47px;
        width: 275px;
      }

      .test-probes {
        margin-top: 360px;

        &.t2 {
          margin-top: 365px;
        }

        .react-dropdown-select-content {
          max-width: 90%;
        }

        .test-probe-test-2 {
          display: flex;
          width: 275px;

          .input-test {
            margin-left: -17px;
            margin-bottom: 5.8px;
            height: 31px;

            input {
              text-align: right;
              width: 158px;
            }
          }

          .shape {
            width: 32px;
            height: 31px;
            border-radius: 32px;
            margin-top: 8px;
            margin-left: 18px;
          }
        }
      }

      .react-dropdown-select {
        font-size: 14px;
        width: 95%;
      }

      .react-dropdown-select-content {
        height: 30px;
        display: flex;
        align-items: center;

        div,
        span {
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
          font-size: 14px;
          color: #66615b !important;
          font-family: "Montserrat-Regular" !important;
        }
      }

      .react-dropdown-select-dropdown-handle {
        margin-right: 0px;
      }

      .react-dropdown-select-dropdown {
        span {
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
          padding: 5px 15px;
        }
      }
    }
  }
}
