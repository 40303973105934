.tree {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin: 20px;

  .tree-node {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .tree-sub-node {
    position: relative;
    margin-left: 20px;

    .plus-component {
      margin-left: 0px;
    }
  }

  .tree-child {
    display: flex;
    flex-direction: column;

    .items {
      display: flex;
      flex-direction: column;

      .items-row {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .plus-component {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tree-child-ind {
    cursor: pointer;

    &.hidden {
      visibility: hidden;
    }
  }
}
