.select {
  margin: 5px 0px;
  margin-right: 0px;
  color: #9d9d9d;
  font-size: 18px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dddddd;
  font-family: "Montserrat-Regular";
  cursor: pointer;

  &.disabled{
    pointer-events: none;
  }
  
  input {
    font-family: inherit;
    color: inherit;

    &::placeholder {
      color: inherit;
      font-family: inherit;
    }

    &:focus {
      outline: none;
    }
  }

  .react-dropdown-select {
    border: none;
  }

  .react-dropdown-select-dropdown-handle {
    margin-right: 15px;
    color: #4d4d4d
  }

  input {
    border: none;
  }
}
