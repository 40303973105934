.test {
  width: 280px;
  display: flex;
  position: relative;

  .tt {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 3px;

    .button2 {
      svg {
        .c {
          fill: #aa0100;
        }
      }
    }
  }

  .check {
  }

  .label {
    cursor: pointer;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #66615b;
    margin-right: 15px;
    margin-top: 7px;
    position: absolute;
    left: 250px;
    top: -2px;
  }
}
