.companies{
 
    display: flex;
    flex-direction: column;
    background-color: white;
    border-right: 1px solid #E9ECEF;
    overflow: auto;
   
    .companies-company{
        padding-top: 35px;
       // border-right: 1px solid #E9ECEF;
        border-bottom: 1px solid #E9ECEF;
      
    }
    
    
    .companies-details{
        font-family: "Montserrat-Bold";
        font-size: 14px;
        color: #252422;
        margin-bottom: 15px;
        width: 365px;
   
        span:first-of-type{
            display: inline-block;
            width: 90px;
            margin-left: 30px;
            margin-right: 10px;
        }
    }

      
}