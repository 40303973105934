.legend {
  display: flex;
  flex: 1;
  align-self: flex-start;
  flex-direction: column;
  width: calc(100% - 5px);
  height: 100%;
  outline: 5px solid rgb(110, 110, 110);
  font-family: "Montserrat-Regular";
  font-size: 14px;
  overflow: hidden;
}

.legend-btm {
  margin-top: auto;
  width: 100%;
}

// .legend-scale {
//   transform-origin: 0% 50%;
//   display: flex;
// }

// .legend-item {
//   text-align: left;
//   color: black;
//   padding: 7px 0px;
//   overflow: hidden;
//   position: relative;
 
//   .legend-item-lb{
//     position: relative;
//     top: 1px;
//     left: 30px;
//   }

//   .legend-item-img{
//     position: absolute;
//     top: -21px;
//     left: 5px;

//     svg{
//       width: 15px;
//     }

//     &.col0 {
//       svg .a {
//         stroke: #ff6085;
//         fill: #ff6085;
//       }
//     }

//     &.col1 {
//       svg .a {
//         stroke: #51dbb6;
//         fill: #51dbb6;
//       }
//     }

//     &.col2 {
//       svg .a {
//         stroke: #f4e785;
//         fill: #f4e785;
//       }
//     }

//     &.col3 {
//       svg .a {
//         stroke: #51b6db;
//         fill: #51b6db;
//       }
//     }
//   }
// }
