.tab{
 
  color: #2c2c2c;
  font-family: "Montserrat-Regular";
  margin: 11px 60px;
  margin-left: 0px;
  font-size: 14px;
  font-weight: bold;
 
  &.selected {
    color: #AA0100;
  }

  &:hover{
     cursor: pointer;
  }

  &.disabled{
    pointer-events: none;
  }
}