.login {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 321px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 2px 2px 0px #b5b5b5;
  font-family: "Montserrat-Regular";

  .login-title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 15px;
  }

  .login-cnt {
    margin-bottom: 15px;

    .input {
      margin-bottom: 10px;
      border: 1px solid #dddddd;

      &.focus {
        border: 1px solid #b5b5b5;
      }

      input {
        font-family: "Montserrat-Regular";
        margin-left: 0px;
        border: none;
        margin-top: 0px;
        color: #66615B;
        font-weight: bold;

        &::placeholder{
          color: #66615B;
          font-weight: 100;
        }
      }

      img {
        margin-top: 12px;
      }
    }
  }

  .login-err {
    text-align: center;
    color: #aa0100;
    margin-top: 5px;
    font-size: 12px;
    font-family: "Andale-Mono";
    //font-weight: bold;
    height: 14px;
  }

  .login-btm {
    margin-top: 2px;
    margin-bottom: 18px;
  }
}
