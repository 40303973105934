.radio {
  display: flex;
  flex-direction: column;


  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    cursor: pointer;
    //left: 9999px;
    left: 3px;
    top: -2px;
    width: 20px;
    height: 20px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #aa0100;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .radio-items {
    display: flex;
  }

  .radio-item {
    display: flex;
    margin-right: 5px;
    position: relative;
    cursor: pointer;

    .radio-item-label {
      margin-left: 3px;
      font-weight: bold;
      pointer-events: none;
    }

    .radio-item-for {
    }
  }
}
