.tab2 {
  .title {
    margin-left: 137px;
    color: #aa0100;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-weight: bold;
    margin-top: -10px;
  }

  .probes-cnt {
    margin-bottom: 50px;
    margin-top: -15px;

    .probe-cnt {
      font-size: 14px;
      background-color: white;
      border-radius: 6px;
      margin-right: 15px;
      padding: 20px;
      padding-left: 24px;
      margin-left: -10px;
      padding-bottom: 5px;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      border: 1px solid #dddddd;

      .name {
        font-family: "Montserrat-Bold";
        font-size: 14px;
        margin-top: 12px;
        width: 50px;
      }

      .right {
        display: flex;
        margin-top: -5px;
        margin-left: 115px;
        overflow-x: hidden;

        .text-area {
          height: 100px;
          margin-top: 15px;

          textarea {
            color: #66615b;
            font-size: 14px;
            font-family: "Montserrat-Regular";
          }
        }

        .selects {
          display: flex;
          margin-left: -15px;

          .selector {
            margin-left: 15px;

            .react-dropdown-select {
              font-size: 14px;
              color: #66615b;
            }
          }
        }
        .pics {
          display: inline-flex;
          margin-top: 10px;
          flex-flow: row wrap;
          margin-left: -20px;
          align-items: flex-start;
         
          .pic {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-left: 20px;
            margin-top: 15px;
            overflow: hidden;

            .img-arr{
              position: absolute;
            }
           
            > img {
              width: 450px;
            }

            .btm {
              margin-top: 20px;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-left: 20px;
            
              .btn-upl{
                position: relative;

                .fl{
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                }
              }

              .button {
                float: left;
                display: block;
              }

              .btn-photo {
                width: 100px;
              }

              .button1 {
                justify-content: unset;
              }

              .btn-delete {
                width: 50px;
                margin-top: -10px;
                margin-left: -15px;
                height: 60px;
              }
            }
          }
        }

        .material {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .row {
            display: flex;
            margin-bottom: 10px;

            .name {
              width: 65px;
              font-family: "Montserrat-Regular";
              font-size: 14px;
              font-weight: bold;
            }

            .selectors {
              display: flex;
              margin-top: -5px;

              .selector {
                margin-left: 15px;
                width: 250px;

                .react-dropdown-select {
                  font-size: 14px;
                  color: #66615b;
                }
              }
            }
          }
        }
      }
    }
  }
}
