.tab4 {
  font-family: "Montserrat-Regular";
  color: #252422;

  .buttons {
    display: flex;
    margin-left: -10px;

    .btn {
      margin: 0px 10px;
      padding: 10px 25px;
    }
  }

  .section-content-title {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .section-content {
    display: flex;

    .section-content-left {
      width: 50%;
    }

    .visits-checks {
      > .floor {
        > .check-name {
          display: flex;
          > .chk {
            cursor: pointer;
            width: 0;
            height: 0;
            color: red;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #66615b;
            margin-top: 7px;
            margin-right: 4px;
          }
        }

        .check {
          margin: 10px 17px;
        }
      }

      > .check {
        margin: 10px;

        > .label {
        }
      }
    }

    .cmp-panel {
      margin-top: -5px;
      > .tree3 {
        .tree-node {
          margin-top: 5px;
          display: block;

          .tree-sub-node {
            //margin-top: -5px;
            //margin-bottom: -5px;

            &.level2 {
              .tree-sub-node {
                margin-top: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }

        .items-row {
          font-size: 14px;
          color: #252422;
          font-family: "Montserrat-Regular";

          .chk {
            .check {
              margin-top: -3px;
            }

            &.chk4 {
            }

            .tx {
              &.tx4 {
                margin-left: -5px;
              }
            }
          }

          .tree-child-ind {
            svg {
              display: none;
            }
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #66615b;
            margin-top: 7px;
            margin-right: 4px;
            cursor: default;
            //display: none;
          }
        }
      }
    }
  }

  .imgs-proben {
    font-size: 14px;
    color: #252422;
    display: inline-flex;
    flex-flow: row wrap;

    .img-proben {
      width: 460px;
      flex-basis: 460px;
      min-height: 270px;
      border: 1px solid #dddddd;
      border-radius: 6px;
      padding: 20px;
      position: relative;
      margin-right: 25px;
      margin-bottom: 25px;
         
      .img-proben-id {
        float: right;
        background-color: #66615b;
        border-radius: 10.5px;
        padding: 5px 10px;
        color: #ffffff;
        font-size: 12px;
        font-family: "Montserrat-Bold";
        cursor: pointer;
      }
      
      .img-proben-check {
        float: right;
        padding: 2px 15px;
        cursor: pointer;
      }

      .img-proben-title {
        min-height: 12px;
        margin-bottom: 30px;
      }

      .img-proben-content {
        margin-top: 20px;
      }

      .img-proben-img {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 15px;
        overflow: hidden;
  
        > .no-cam {
          position: relative;
          left: 50%;
          margin-top: 80px;
          transform: translateX(-50%);
          width: 50px;
        }

        .img-arr{
          position: absolute;
        }

        > .img-photo {
          width: 450px;
        }
      }
    }
  }
}
