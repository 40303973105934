.home {
  display: flex;
  flex: 1;
}

.bg-gray {
  background-color: #f8f8f8 !important;
}

.border-top-gray {
  // border-top: 1px solid #F8F8F8 !important;
}

.file {
  position: absolute;
  top: 400px;
 
  .file-input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 60px;
      opacity: 0;
  }

  .file-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
   
  }
}
